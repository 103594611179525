import { MaybeComputedRefOrFalsy } from '@unhead/vue';

export default function ({
  title,
  description,
  image,
  type
}: {
  title: MaybeComputedRefOrFalsy<string>;
  description: MaybeComputedRefOrFalsy<string>;
  image: MaybeComputedRefOrFalsy<string>;
  type: MaybeComputedRefOrFalsy<'article' | 'website' | 'book' | 'profile'>;
}) {
  const {
    public: { cloudflareImageProcessing: cfUrl }
  } = useRuntimeConfig();
  const { t } = useI18n();

  const twitterCard = type === 'article' ? 'summary_large_image' : 'summary';

  function fixImageUrl(value: string) {
    if (value && !value.startsWith('/') && !value.includes(cfUrl)) {
      return `${cfUrl}/image?w=1200&h=630&f=webp&fit=crop&image=${value}`;
    }
    return value;
  }

  const processedImage =
    typeof image === 'string'
      ? fixImageUrl(image)
      : typeof image === 'function'
      ? () => fixImageUrl(image())
      : image;

  useSeoMeta({
    ...(title
      ? {
          title,
          ogTitle: title,
          twitterTitle: title
        }
      : {}),
    ...(description
      ? {
          description,
          ogDescription: description,
          twitterDescription: description
        }
      : {}),
    ...(image
      ? {
          ogImage: processedImage,
          twitterImage: processedImage
        }
      : {}),
    ...(type
      ? {
          ogType: type || 'website',
          twitterCard
        }
      : {}),
    ogSiteName: t('seo.defaultTitle')
  });
}
